import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardMedia, useMediaQuery } from '@mui/material';

const BULLET: JSX.Element = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(1.8)' }}
  >
    •
  </Box>
);

const PASTA_SPRITES = require.context("./images/pasta_sprites", true);

export interface Pasta {
  index: number,
  displayName: string,
  isVegetarian: boolean,
  isVegan: boolean,
  origins: string,
  recipeUrl: string | null,
}

interface PastaProps {
  pasta: Pasta
}

function capEveryFirstLetter(s: string) {
  return s.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

export default function PastaCard(props: PastaProps) {

  const desktopUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  //const tabletUp = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  //const mobileUp = useMediaQuery((theme: any) => theme.breakpoints.up("xs"));

  const INIT_WIDTH: number = desktopUp ? 180 : 180;

  return (
    <Card
      sx={{
        minWidth: INIT_WIDTH,
        maxWidth: INIT_WIDTH,
        border: "none",
        boxShadow: "none",
      }}
    >
      <CardActionArea href={props.pasta.recipeUrl !== null ? props.pasta.recipeUrl : `https://www.google.com/search?q=${props.pasta.displayName}`} target="_blank">
        <CardMedia
          component="img"
          width={INIT_WIDTH}
          height={INIT_WIDTH}
          image={PASTA_SPRITES(`./${props.pasta.index}.png`)}
          alt="pasta"
        />
        <CardContent>
          <Typography sx={{ mb: 0, fontSize: 14 }} component="div">
            #{props.pasta.index} : {capEveryFirstLetter(props.pasta.displayName)}
          </Typography>
          <Typography sx={{ mb: 0, fontSize: 10 }} color="text.secondary">
          {props.pasta.isVegetarian ? <>Vegetarian {BULLET} </> : <></>}
          {props.pasta.isVegan ? <>Vegan {BULLET} </> : <></>}
          origins: {props.pasta.origins}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
