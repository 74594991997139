import React, { useState } from 'react';

import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Box } from "@mui/material";

import spreadsheet from "./images/about/spreadsheet.png";
import pastas_json from "./images/about/pastas_json.png";
import illustrator from "./images/about/illustrator.png";
import layers from "./images/about/layers.png";
import react_mui from "./images/about/react_mui.png";

export default function About(){
  
  // about
  const [openAbout, setOpenAbout] = useState(false);

  return (
    <>
    
      <Grid container justifyContent="center">
        <Button onClick={() => setOpenAbout(true)}> How does this work? </Button>
      </Grid>

      <Dialog
        open={openAbout}
        onClose={() => setOpenAbout(false)}
        scroll={"paper"}
      >

        <DialogTitle>What is 99 Pastas and how does it work</DialogTitle>

        <DialogContent dividers={true}>
          <DialogContentText
            tabIndex={-1}
          >

            This website is an index for 99 italian pasta recipes, to provide cooking inspiration, as I failed to find such a list myself
            <br /><br />

            Data about 99 pastas is collected from several officially italian people
            <br /><br />

            <Box component="img" sx={{ width: 512 }} alt="spreadsheet" src={spreadsheet} />
            <br /><br />

            A Python script creates pasta metadata JSONs (origin, isVegetarian, etc.) from the spreadsheet
            <br /><br />

            <Box component="img" sx={{ width: 512 }} alt="spreadsheet" src={pastas_json} />
            <br /><br />

            Vector graphics are made with Adobe Illustrator, each ingredient as an independant layer in grayscale
            <br /><br />

            <Box component="img" sx={{ width: 512 }} alt="spreadsheet" src={illustrator} />
            <br /><br />

            A Python script creates pasta sprite representation JSONs from the spreadsheet cells values and background color,
            then creates the PNG sprites by stacking grayscale layers, and blend-multiplies pixels with colors (using Pillow)
            <br /><br />

            <Box component="img" sx={{ width: 512 }} alt="spreadsheet" src={layers} />
            <br /><br />

            The pasta metadata and sprites are displayed on the website, made with React / Material UI, and hosted on Netlify
            <br /><br />

            <Box component="img" sx={{ width: 512 }} alt="spreadsheet" src={react_mui} />
            <br /><br />

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenAbout(false)}>Close</Button>
        </DialogActions>

      </Dialog>
    </>
  );
}
