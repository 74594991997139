import React, { useState } from 'react';

import PastaCard from './PastaCard';
import About from "./About";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';

import banner from "./images/banner.png";
import pastas from "./pastas.json";

function App() {

  // for debugging

  /*
  const desktopUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const midUp = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  const tabletUp = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const mobileUp = useMediaQuery((theme: any) => theme.breakpoints.up("xs"));

  const breakpoint: string = desktopUp ? "lg" : midUp ? "md" : tabletUp ? "sm" : mobileUp ? "xs" : "<xs!";
  */

  // filtering
  const [filterValue, setFilterValue] = useState("all");

  function handleFilterChange(event: React.ChangeEvent<HTMLInputElement>, value: string){
    setFilterValue(value);
  };

  return (
    <>
      <Grid container justifyContent="center">
          <Box component="img" sx={{ height: 256, }} alt="banner" src={banner} />
      </Grid>

      <br />

      <About />

      <br />

      <Grid container justifyContent='center'>
          <RadioGroup
            row
            value={filterValue}
            onChange={handleFilterChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="vegetarian" control={<Radio />} label="Vegetarian" />
            <FormControlLabel value="vegan" control={<Radio />} label="Vegan" />
          </RadioGroup>
      </Grid>

      <Grid container spacing={0}>
      {
        pastas
          .filter(pasta => filterValue === "vegan" ? pasta.isVegan : filterValue === "vegetarian" ? pasta.isVegetarian : true)
          .map(pasta =>
          <Grid item xs={6} md={3} lg={1.2} key={pasta.index}>
            <PastaCard pasta={pasta}></PastaCard>
          </Grid>
        )
      }
      </Grid>
    </>
  );
}

export default App;
